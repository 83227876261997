<template>
  <div class="login_container">
    <div class="login_box">
      <div style="margin:20px 0; text-align:center; font-size:24px; color: #000000;">
        <b>FEED后台</b>
      </div>
      <!-- 用户名-->
      <el-form ref="LoginFormRef" :model="loginForm" :rules="LoginFormRules">
        <el-form-item prop="username">
          <el-input size="medium" style="margin:10px 0px;width: 300px;margin-left:25px" v-model="loginForm.username"
                    prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 密码-->
        <el-form-item prop="password">
          <el-input size="medium" style="margin:10px 0px;width: 300px;margin-left:25px" show-password
                    v-model="loginForm.password" prefix-icon="el-icon-lock" type="password"></el-input>
        </el-form-item>
        <div style="margin:10px 0; text-align:center">
          <el-button type="primary" size="small" @click="login">登录</el-button>
          <el-button type="warning" size="small" @click="register">注册</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
   
<script>
import { setRoutes } from "@/router"
import { resetRouter } from "@/router"
export default {
  name: "Login",
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      LoginFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {

    login () {
      this.$refs['LoginFormRef'].validate(async (valid) => {
        if (valid) {
          this.request.post("/user/login", this.loginForm).then(res => {
            if (res.code === 200) {
              localStorage.setItem("user", JSON.stringify(res.data))//存储用户信息到浏览器
              localStorage.setItem("menus", JSON.stringify(res.data.menus))//存储用户权限菜单信息到浏览器
              //动态设置当前用户的路由
              setRoutes()
              this.$router.push("/home")
              this.$message.success("登录成功")
            } else {
              this.$message.error(res.msg)
            }
          })
        }

      })
    },

    //重置
    resetLoginForm () {
      this.$refs.LoginFormRef.resetFields()
    },

    //register
    register () {
      this.$router.push("/register")
    }
  }
}
</script>
   
<style lang="less" scoped>
iframe {
  /*这是关键，让iframe变成块元素，就能不出现滚动条 */
  display: block;
}

.login_container {
  /* background-color: transparent; */
  /* background-color: #f0f0f0; */
  /* 更改整体背景颜色 */
  height: 100%;
  background-image: url(../assets/images/bbg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.canvas {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 1;
}

.login_box {
  width: 350px;
  height: auto;
  // background: #b09685;
  /* 设置透明背景 */
  border-radius: 10px;
  /* 调整边框圆角 */
  box-shadow: 0 0 20px rgba(247, 179, 133, 0.5);
  /* box-shadow: 0 0 30px rgba(173, 216, 230, 0.5); */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

/* .login_box {
    width: 350px;
    height: 300px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
} */

/* 调整输入框样式 */
.el-input {
  width: 100%;
  margin: 10px 0;
}

/* 调整按钮样式 */
.el-button {
  margin-right: 10px;
}

::v-deep .el-input__inner {
  background-color: transparent !important;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
}
</style>

<style lang='less' scoped>
/deep/ .el-form-item__error {
  left: 25px;
}
</style>

   