import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import store from "@/store"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '*',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',  //将mode改成hash可以解决nginx部署刷新404问题，也可以在nginx中解决
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 提供一个重置路由的方法
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
  })
}

//刷新页面会重置路由
export const setRoutes = () => {
  const storeMenus = localStorage.getItem("menus")
  if (storeMenus) {
    //拼装动态路由
    const manageRoute = {
      path: '/', name: 'Manage', component: () => import('../views/Manage.vue'), redirect: '/home', children: [
        { path: 'person', name: '个人信息', component: () => import('../views/Person.vue') },
        { path: 'password', name: '修改密码', component: () => import('../views/Password.vue') }
      ]
    }

    const menus = JSON.parse(storeMenus)

    menus.forEach(item => {
      if (item.path) {//当且仅当path不为空的时候才去设置路由
        let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import('../views/' + item.pagePath + '.vue') }
        manageRoute.children.push(itemMenu)
      } else if (item.children.length) {
        item.children.forEach(item => {
          if (item.path) {
            let itemMenu = { path: item.path.replace("/", ""), name: item.name, component: () => import('../views/' + item.pagePath + '.vue') }
            manageRoute.children.push(itemMenu)
          }
        })
      }
    })
    //获取当前的路由对象名称数组
    const currentRouteNames = router.getRoutes().map(v => v.name)
    if (!currentRouteNames.includes('Manage')) {
      //动态加载到想在的路由对象
      router.addRoute(manageRoute)
    }
  }
}
setRoutes()

//路由守卫
router.beforeEach((to, from, next) => {
  // console.log(to)


  localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
  store.commit("setPath")


  // 未找到路由的情况
  if (!to.matched.length) {
    const storeMenus = localStorage.getItem("menus")

    // console.log("未找到路由的情况 storeMenus == " + storeMenus)

    if (storeMenus) {
      next("/404")
    } else {
      // 跳回登录页面
      next("/login")
    }
  }


  // 其他的情况都放行
  next()

})

export default router
