<template>
  <div class="login_container">

    <div class="login_box">
      <div style="margin:20px 0; text-align:center; font-size:24px; color: #000000;">
        <b>注册</b>
      </div>

      <el-form ref="SigninRules" :model="signin" :rules="SigninRules">
        <!-- 用户名-->
        <el-form-item prop="username">
          <el-input
            size="medium"
            style="margin:10px 0px;width: 300px;margin-left:25px"
            v-model="signin.username"
            prefix-icon="el-icon-user"
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>

        <!-- 密码-->
        <el-form-item prop="password">
          <el-input
            size="medium"
            style="margin:10px 0px;width: 300px;margin-left:25px"
            show-password
            v-model="signin.password"
            prefix-icon="el-icon-lock"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>

        <!-- 确认密码-->
        <el-form-item prop="password2">
          <el-input
            size="medium"
            style="margin:10px 0px;width: 300px;margin-left:25px"
            show-password
            v-model="signin.password2"
            prefix-icon="el-icon-lock"
            type="password"
            placeholder="请确认密码"
          ></el-input>
        </el-form-item>

        <div style="margin:10px 0; text-align:center">
          <el-button type="primary" size="small" @click="toLogin">登录</el-button>
          <el-button type="warning" size="small" @click="register">注册</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "Signin",
  data () {
    return {
      signin: {
        username: '',
        password: '',
        password2: ''
      },
      SigninRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        password2: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { validator: this.validatePassword2, trigger: 'blur' } // 添加自定义验证规则
        ],
      },
      options: [],
    }
  },
  created () {
    this.getSchool()
  },
  methods: {

    // 验证密码是否一致的自定义验证规则方法
    validatePassword2 (rule, value, callback) {
      if (value !== this.signin.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    },

    toLogin () {
      this.$router.push("/login")
    },
    register () {
      // 检查必填字段是否为空
      if (!this.signin.username || !this.signin.password || !this.signin.password2) {
        this.$message.error('请填写完整信息')
        return
      }

      // 检查确认密码是否与密码一致
      if (this.signin.password !== this.signin.password2) {
        this.$message.error('两次输入的密码不一致')
        return
      }

      this.request.post("/user", this.signin).then(res => {
        if (res.code === 200) {
          this.$message.success("注册成功")
          this.$router.push("/login")
        } else {
          this.$message.error("注册失败")
        }
      })
    },
  }
}
</script>
 
<style scoped>
.login_container {
  /* background-color: transparent; */
  /* background-color: #f0f0f0; */
  /* 更改整体背景颜色 */
  height: 100%;
  background-image: url(../assets/images/bbbbg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.login_box {
  width: 350px;
  height: auto;
  /* background: rgba(80, 100, 120, 0.6); */
  /* 设置透明背景 */
  border-radius: 10px;
  /* 调整边框圆角 */
  box-shadow: 0 0 20px rgba(130, 120, 250, 0.5);
  /* box-shadow: 0 0 30px rgba(173, 216, 230, 0.5); */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

/* 调整输入框样式 */
.el-input {
  width: 100%;
  margin: 10px 0;
}

/* 调整按钮样式 */
.el-button {
  margin-right: 10px;
}

::v-deep .el-input__inner {
  background-color: transparent !important;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
}
</style>

<style lang='less' scoped>
/deep/ .el-form-item__error {
  left: 25px;
}
</style>

 